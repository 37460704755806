import { Link } from 'gatsby'
import React from 'react'
import CompanyPreview from './company-preview'
import Filter from './filter';
import Container from '../components/container'

import styles from './company-preview-grid.module.css'

function CompanyPreviewGrid (props) {
  return (
    <div className={styles.root} id="companies">
      <Container>
        <div className={styles.titleBar}>
          <div>
            {props.title && (
              <h3 className={`${styles.headline} ${styles.bullet}`}>
                {props.title}
              </h3>
            )}
            <p className={styles.subtitle}>{props.description}</p>
          </div>
          <div className={styles.filters} data-company-filters>
            <Filter 
              title="Sectors"
              filter="company"
              options={props.sectors}
            />
            <Filter 
              title="Stage"
              filter="company"
              options={props.stages}
            />
          </div>
        </div>
      </Container>

      <Container size="large">
        <div className={`${styles.grid} ${styles.featured}`} data-hide-filtered>
          {props.featured &&
            props.featured.map((node, index) => (
                <CompanyPreview key={node._id} index={index} {...node} />
            ))}
        </div>
        <div className={`${styles.grid} ${styles.filtered}`} data-display-items>
          {props.nodes &&
            props.nodes.map(node => (
                <CompanyPreview key={node._id} {...node} />
            ))}
        </div>
      </Container>

      <Container>
        <div className={styles.footBar}>
          {props.followup !== ' ' && (
            <div>
              <p className={styles.subtitle}>{props.followup}</p>
            </div>
          )}
          <div>
            {props.browseMoreHref && (
              <Link className={`${styles.button} ${styles.primary}`} to={props.browseMoreHref}>See all companies</Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

CompanyPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default CompanyPreviewGrid

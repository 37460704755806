import React from 'react'
import { graphql, Link } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
// import SmoothScroll from 'smooth-scroll'
import Hero from '../components/hero-home'
import GraphQLErrorList from '../components/graphql-error-list'
import CompanyPreviewGrid from '../components/company-preview-grid'
// import PeoplePreviewGrid from '../components/people-preview-grid'
// import NewsPostPreviewGrid from '../components/news-post-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import BlockHeader from '../components/block-header'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      socialLinkedin
      socialInstagram
      socialMedium
      socialTwitter
    }

    homepage: sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }) {
      title
      metaDescription
      metaKeywords
      _rawHeroText
      companiesTitle
      companiesDescription
      companiesFollowup
      featuredCompanies {
        title
        _id
        _key
        slug {
          current
        }
        _rawExcerpt
        link
        investmentDate
        logo {
          asset {
            _id
            url
          }
        }
        brandColor {
          hex
        }
        mainImage {
          asset {
            _id
            url
          }
        }
        sectors {
          _id
          title
        }
        stage {
          _id
          title
        }
      }
      peopleTitle
      peopleDescription
      newsTitle
      newsDescription
    }

    sectors: allSanitySector(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
        }
      }
    }

    stages: allSanityStage(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
        }
      }
    }

    companies: allSanityCompany(filter: {display: {ne: false}}, sort: {fields: investmentDate, order: DESC}) {
      edges {
        node {
          title
          _id
          _key
          slug {
            current
          }
          _rawExcerpt
          link
          investmentDate
          logo {
            asset {
              _id
              url
            }
          }
          brandColor {
            hex
          }
          mainImage {
            asset {
              _id
              url
            }
          }
          sectors {
            _id
            title
          }
          stage {
            _id
            title
          }
        }
      }
    }

    people: allSanityPerson(sort: {fields: lastName, order: ASC}) {
      edges {
        node {
          id
          profileColor {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          profileGrey {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
          position
          team
          slug {
            current
          }
        }
      }
    }

    portfolioNews: allSanityPost(limit: 1, sort: {fields: publishedAt, order: DESC}, filter: {categories: {nin: ["redpoint","team","events"]}}) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          link
        }
      }
    }

    redpointNews: allSanityPost(limit: 1, sort: {fields: publishedAt, order: DESC}, filter: {categories: {eq: "redpoint"}}) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          link
        }
      }
    }

    teamNews: allSanityPost(limit: 1, sort: {fields: publishedAt, order: DESC}, filter: {categories: {eq: "team"}}) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          link
        }
      }
    }

    eventsNews: allSanityPost(limit: 1, sort: {fields: publishedAt, order: DESC}, filter: {categories: {eq: "events"}}) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          link
        }
      }
    }
  }
`

class IndexPage extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    // SmoothScroll('a[href*="#"]');
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]')
    }
  }

  render () {
    const { data, errors } = this.props

    if (errors) {
      return (
        <Layout>
          <GraphQLErrorList errors={errors} />
        </Layout>
      )
    }

    const site = (data || {}).site
    const homepage = (data || {}).homepage
    const sectorNodes = (data || {}).sectors
      ? mapEdgesToNodes(data.sectors)
      : []
    const stageNodes = (data || {}).stages
      ? mapEdgesToNodes(data.stages)
      : []
    const companyNodes = (data || {}).companies
      ? mapEdgesToNodes(data.companies).filter(filterOutDocsWithoutSlugs)
      : []
    // const personNodes = (data || {}).people
    //   ? mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)
    //   : []
    // const portfolioNodes = (data || {}).portfolioNews
    //   ? mapEdgesToNodes(data.portfolioNews)
    //   : []
    // const redpointNodes = (data || {}).redpointNews
    //   ? mapEdgesToNodes(data.redpointNews)
    //   : []
    // const teamNodes = (data || {}).teamNews
    //   ? mapEdgesToNodes(data.teamNews)
    //   : []
    // const eventsNodes = (data || {}).eventsNews
    //   ? mapEdgesToNodes(data.eventsNews)
    //   : []

    if (!site) {
      throw new Error(
        'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
      )
    }

    return (
      <Layout page='home'>
        <SEO title={homepage.title} description={homepage.metaDescription} keywords={homepage.metaKeywords} />
        <h1 hidden>{homepage.title}</h1>

        <Hero>
          <BlockHeader blocks={homepage._rawHeroText} />
        </Hero>

        {companyNodes && (
          <CompanyPreviewGrid
            title={homepage.companiesTitle}
            description={homepage.companiesDescription}
            sectors={sectorNodes}
            stages={stageNodes}
            featured={homepage.featuredCompanies}
            nodes={companyNodes}
            followup={homepage.companiesFollowup}
            browseMoreHref='/companies/'
          />
        )}

        {/* {personNodes && (
          <PeoplePreviewGrid
            title={homepage.peopleTitle}
            description={homepage.peopleDescription}
            nodes={personNodes}
            browseMoreHref='/our-people/'
          />
        )} */}

        {/* {(portfolioNodes && redpointNodes && teamNodes && eventsNodes) && (
          <NewsPostPreviewGrid
            display='homepage'
            title={homepage.newsTitle}
            description={homepage.newsDescription}
            socials={site}
            // nodes={postNodes}
            portfolioNodes={portfolioNodes}
            redpointNodes={redpointNodes}
            teamNodes={teamNodes}
            eventsNodes={eventsNodes}
            browseMoreHref='/news/'
          />
        )} */}
      </Layout>
    )
  }
}

export default IndexPage

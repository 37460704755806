import React from 'react'
import Icon from './icons'
import Container from './container'

import styles from './hero.module.css'

function Hero ({ children }) {
  return (
    <div className={`${styles.root} ${styles.homepage}`}>
        <Container>
            <h2 className={`${styles.headline} ${styles.bullet}`}>
                {children}
            </h2>
            <a href="#companies" className={styles.viewmore}><Icon symbol='viewmore' /></a>
        </Container>
    </div>
  )
}

export default Hero

import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import Icon from './icons'
import BlockText from './block-text'

import styles from './company-preview.module.css'

class CompanyPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showModal: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState({ showModal: !this.state.showModal })
  }

  render() {
    const blockStyles = {};
    const cardStyles = {};
    let sectors = '';
    let stages = '';

    // Assign main image
    if (this.props.mainImage && this.props.mainImage.asset && !this.props.plain) {
      blockStyles.backgroundImage = `url(${imageUrlFor(buildImageObj(this.props.mainImage))}?w=700&h=380&fit=crop&crop=center)`;
    }

    // Assign logo
    if (this.props.brandColor && this.props.brandColor.hex && !this.props.plain) {
      blockStyles.backgroundColor = this.props.brandColor.hex;
    }

    // Assign Card Background
    if (this.props.brandColor && this.props.brandColor.hex) {
      cardStyles.backgroundColor = this.props.brandColor.hex;
    }

    // Generate sector list
    const sectorsList = [];
    if (this.props.sectors.length > 0) {
      for (let sec = 0; sec < this.props.sectors.length; sec++) {
        sectorsList.push(this.props.sectors[sec].title);
      }
      sectors = sectorsList.join(',');
    }

    // Generate stage list
    const stagesList = [];
    if (this.props.stage.length > 0) {
      for (let sta = 0; sta < this.props.stage.length; sta++) {
        stagesList.push(this.props.stage[sta].title);
      }
      stages = stagesList.join(',');
    }

    let cardLogo = this.props.logoColor;
    if (!this.props.logoColor) {
      cardLogo = this.props.logo;
    }
    
    return (
      <>
      <div className={`${styles.root} ${this.props.border === true ? styles.border : ''} ${([0,3,6,9].indexOf(this.props.index) > -1) ? styles.highlight : styles.standard}`} onClick={this.handleClick} data-filter-sectors={sectors} data-filter-stage={stages} data-filter-name={this.props.title}>
        <div className={styles.background} style={blockStyles}></div>
        <Link to={`/companies/${this.props.slug.current}/`}></Link>
        <div className={styles.block}>
          {cardLogo && cardLogo.asset && (
            <img
              src={imageUrlFor(buildImageObj(cardLogo))
                .url()}
              alt={this.props.title}
            />
          )}
        </div>
        <div className={styles.hoverData}>
            {sectorsList && `${sectorsList[0]} | `}
            {stagesList && `${stagesList[0]}`}
            {this.props.investmentDate && (
              <>
                {` | ${this.props.investmentDate.split('-')[0]}`}
              </>
            )}
        </div>
  
        {/* <div className={styles.hoverContainer}>
          <h6 className={styles.learnMore}>Learn more <Icon symbol="chevronright" /></h6>
        </div> */}
  
        <div className={`${styles.modal} ${this.state.showModal ? styles.showModal : styles.hideModal}`}>
          <div className={styles.modalWrap}>
            <span className={styles.closeIcon}>
              <Icon symbol="close" />
            </span>
            <div className={styles.modalContent}>
              <div>
                <h3 className={`${styles.headline} ${styles.bullet}`}>{this.props.title}</h3>
                {this.props._rawExcerpt && <BlockText blocks={this.props._rawExcerpt} />}
                <a href={this.props.link} target="_blank">{this.props.link}</a>
              </div>
            </div>
            {/* {blockStyles.backgroundImage && (
              <div className={styles.modalVisual} style={{backgroundImage: blockStyles.backgroundImage}}></div>
            )} */}
            {/* {!blockStyles.backgroundImage && ( */}
              <div className={styles.modalVisual} style={cardStyles}>
                {this.props.logo && this.props.logo.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(this.props.logo))
                      .url()}
                    alt={this.props.title}
                  />
                )}
                {!this.props.logo && this.props.logoColor && this.props.logoColor.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(this.props.logoColor))
                      .url()}
                    alt={this.props.title}
                    className={styles.fakeWhite}
                  />
                )}
              </div>
            {/* )} */}
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default CompanyPreview

import React from 'react'
import Icon from './icons'
import { gsap } from "gsap";
import { ScrollToPlugin } from 'gsap/all';

import styles from './filter.module.css'

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {title: props.title}
        this.toggleFilter = this.toggleFilter.bind(this)
        this.hideFilter = this.hideFilter.bind(this)
        this.processFilter = this.processFilter.bind(this)

        gsap.registerPlugin(ScrollToPlugin);
    }

    componentDidMount() {
        if (this.props.title === "Sectors") {
            const query = window.location.search.substring(1);
            // console.log(query);
            if (query !== '') {
                const button = document.querySelector(`[data-filter-list="sectors"][data-filter-term='${query}']`);
                // console.log(button);
                if (button) {
                    this.processFilter(button);
                }
            }
        }
      }

    hideFilter(e) {
        const element = e.target;

        if (!(element === this.target) && !(element.closest(`.${this.target.className.replace(' ', '.')}`))) {
            this.target.classList.remove(styles.open);
            this.target = false;

            document.removeEventListener('mousedown', this.hideFilter, false);
        }
    }

    toggleFilter(e) {
        const element = e.currentTarget;
        if (element.classList.contains(styles.open)) {
            element.classList.remove(styles.open);
            this.target = false;

            document.removeEventListener('mousedown', this.hideFilter, false);
        } else {
            element.classList.add(styles.open);
            this.target = element;

            document.addEventListener('mousedown', this.hideFilter, false);
        }
        
    }

    processFilter(e) {
        // Set current filter
        // const element = e.target;
        const element = 'target' in e ? e.target : e;
        const list = element.parentNode;
        const current = list.querySelector('.current');
        const classes = current.className;
        current.className = '';
        element.className = classes;
        let termLabel = element.dataset.filterTerm
        if (termLabel === '') {
            termLabel = element.dataset.filterDefault
        }
        this.setState({ title: termLabel });

        // Get current filters
        const filterWrap = document.querySelector(`[data-${this.props.filter}-filters]`);
        const filters = filterWrap.querySelectorAll('[data-filter]');
        const checkFilters = [];
        for (var i in filters) if (filters.hasOwnProperty(i)) {
            const currentFilter = filters[i].querySelector('.current');
            const list = currentFilter.dataset.filterList;
            const term = currentFilter.dataset.filterTerm;
            checkFilters.push({list, term});
        }

        // Enable filter
        const filteredContainer = document.querySelector(`[data-hide-filtered]`);
        if (filteredContainer) {
            filteredContainer.style.display = 'none';
        }
        const displayContainer = document.querySelector(`[data-display-items]`);
        // list.parentNode.style.pointerEvents = "none";
        gsap.to(window, { duration: 1, scrollTo: {y: "[data-display-items]", offsetY: 50}, ease: "power4.inOut" });
        gsap.fromTo('[data-display-items]', { opacity: 1 }, { duration: 0.3, opacity: 0, onComplete: () => {
            // displayContainer.innerHTML = '';
            displayContainer.style.display = 'grid';
            const children = displayContainer.childNodes;
            for (let c = 0; c < children.length; c++) {
                let enable = true;
                for (let f = 0; f < checkFilters.length; f++) {
                    const listName = checkFilters[f].list.charAt(0).toUpperCase() + checkFilters[f].list.slice(1);
                    const attr = `filter${listName}`;
                    // console.log(attr, children[c].dataset);
                    const value = children[c].dataset[attr];
                    // console.log(value);
                    if (value.indexOf(checkFilters[f].term) === -1) {
                        enable = false;
                    }
                }
                if (enable) {
                    // const clone = children[c].cloneNode(true);
                    // displayContainer.appendChild(clone);
                    children[c].style.display = 'block';
                } else {
                    children[c].style.display = 'none';
                }
            }

            gsap.fromTo('[data-display-items]', { opacity: 0 }, { duration: 0.3, opacity: 1 });
            // list.parentNode.style.pointerEvents = "auto";
        }});
    }

    render() {
        return (
            <div className={styles.filter} onClick={e => this.toggleFilter(e)}>
                <span data-label className={styles.label}>
                    {this.state.title} <Icon symbol='chevrondown' />
                </span>
                <div data-filter className={styles.list}>
                    <span className={`${styles.current} current`} data-filter-list={this.props.title.toLowerCase()} data-filter-term="" data-filter-default={this.props.title} onClick={e => this.processFilter(e)}>All {this.props.title}</span>
                    {this.props.options && 
                        this.props.options.map(option => (
                            <span key={option.id} onClick={e => this.processFilter(e)} data-filter-list={this.props.title.toLowerCase()} data-filter-term={option.title}>
                                {option.title}
                            </span>
                    ))}
                </div>
            </div>
        )
    }
}

export default Filter
